import * as THREE from "three";
import Experience from "./Experience.js";
import { FlyControls } from "./CustomFlyControls.js";

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;
    this.clock = new THREE.Clock(); // Gestion du temps pour FlyControls.

    this.setInstance(); // Crée l'instance de la caméra.
  }

  // Configuration de la caméra principale
  setInstance() {
    this.instance = new THREE.PerspectiveCamera(
      55, // Champ de vision
      this.sizes.width / this.sizes.height, // Rapport largeur/hauteur
      0.1, // Plan proche
      1000 // Plan éloigné
    );
    this.instance.position.set(0, 0, 10); // Position initiale de la caméra
    this.scene.add(this.instance); // Ajout à la scène
  }

  // Configuration des FlyControls
  setControls() {
    this.controls = new FlyControls(this.instance, this.canvas);

    this.controls.movementSpeed = 10; // Vitesse de déplacement réduite pour le mobile
    if (this.experience.debug.active) {
      this.experience.debug.ui.add(this.controls, "movementSpeed", 0, 100);
    }

    this.controls.rollSpeed = Math.PI / 4; // Rotation plus lente
    if (this.experience.debug.active) {
      this.experience.debug.ui.add(
        this.controls,
        "rollSpeed",
        Math.PI / 6,
        Math.PI
      );
    }

    this.controls.autoForward = false;
    this.controls.dragToLook = true;

    this.isTouching = false;
    this.touchStartX = 0;
    this.touchStartY = 0;
  }

  // Gestion du redimensionnement de l'écran
  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix(); // Met à jour la matrice de projection
  }

  // Mise à jour des contrôles dans la boucle d'animation
  update() {
    const delta = this.clock.getDelta(); // Temps écoulé depuis la dernière frame
    if (this.controls) {
      this.controls.update(delta); // Met à jour FlyControls
    }
  }
}
