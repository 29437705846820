import * as THREE from "three";

import Debug from "./Utils/Debug.js";
import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import World from "./World/World.js";
import Resources from "./Utils/Resources.js";
import TouchIconImg from "../assets/img/touchIcon.png";

import sources from "./sources.js";
import UI from "./UI.js";

import * as prismic from "@prismicio/client";
import MyStar from "./World/MyStar.js";
import gsap from "gsap";
const repositoryName = "lemanstars";
// const client = prismic.createClient(repositoryName, { fetch });

let instance = null;

export default class Experience {
  constructor(_canvas) {
    // Singleton
    if (instance) {
      return instance;
    }
    instance = this;

    // Global access
    window.experience = this;

    // Options
    this.canvas = _canvas;

    // Setup
    this.debug = new Debug();
    this.sizes = new Sizes();
    this.time = new Time();
    this.scene = new THREE.Scene();
    this.resources = new Resources(sources);
    this.camera = new Camera();
    this.renderer = new Renderer();
    this.world = new World();
    this.ui = new UI();

    this.step = 0;
    this.isFinishedLoader = false;

    // Resize event
    this.sizes.on("resize", () => {
      this.resize();
    });

    // Time tick event
    this.time.on("tick", () => {
      this.update();
    });
  }

  initDOM() {
    this.infoTextDOM = document.querySelector(".infoText");
    this.tutoDOM = document.querySelector(".tuto_container");
  }

  async init() {
    this.initDOM();

    window.digitalData.page.pageInfo.pageName =
      "Leman:Toutes les etoiles:Accueil";
    document.dispatchEvent(new CustomEvent("SPAPageTriggered"));

    this.initPrismic();
    const starsData = await this.ui.GET_RetrieveAllStars();

    if (starsData.items) {
      this.starsData = starsData.items; // Stockez les données des étoiles
      this.world.stars.init(this.starsData.length);
      this.world.stars.addStarLabels(this.starsData); // Ajoutez les labels
    }

    if (localStorage.getItem("user")) {
      let userStore = JSON.parse(localStorage.getItem("user"));
      let userData = userStore.additionalData.form_data.first_name;
      let etoileName = userStore.additionalData.form_data.Etoile;

      this.myStar = new MyStar(true, etoileName);
    }

    this.checkStep();
  }

  checkStep() {
    if (this.myStar) {
      this.step = 10;
    }

    this.initDOMDependsOnStep();
  }

  initDOMDependsOnStep() {
    if (this.step === 1) {
      this.infoTextDOM.innerText =
        "Félicitation ! La naissance de ton étoile a fait progresser la jauge, le prochain contenu sera bientôt débloqué.";

      gsap.to(this.infoTextDOM, {
        display: "initial",
        opacity: 1,
        onComplete: () => {
          gsap.to(this.infoTextDOM, {
            scale: 1.1,
            onComplete: () => {
              gsap.to(this.infoTextDOM, {
                scale: 1,
              });
            },
          });
        },
      });

      gsap.to(this.infoTextDOM, {
        opacity: 0,
        delay: 3,
        onComplete: () => {
          // document.querySelector(".find-stars__container").style.display =
          //   "flex";
          this.step = 2;

          if (window.innerWidth < 1024) {
            this.tutoDOM.innerHTML = `
            <div class="tuto-div tuto-1">
              <img class="TouchIconImg" src="${TouchIconImg}" />
              <p>Pour explorer l’espace, touche l'écran pour avancer, et glisse pour choisir la direction</p>
              <button id="next-tuto-1">Suivant</button>
            </div>
          `;
          } else {
            this.tutoDOM.innerHTML = `
            <div class="tuto-div tuto-1">
              <img class="TouchIconImg" src="${TouchIconImg}" />
              <p>Pour explorer l’espace, clique sur l'écran pour avancer, et glisse ta souris pour choisir la direction</p>
              <button id="next-tuto-1">Suivant</button>
            </div>
          `;
          }

          document
            .getElementById("next-tuto-1")
            .addEventListener("click", () => {
              this.step = 3;
              gsap.to(".tuto-1", {
                opacity: 0,
                y: 30,
                display: "none",
                onComplete: () => {
                  this.tutoDOM.innerHTML = `
                    <div class="tuto-div tuto-2">
                      <p>Trouve l'étoile de Léman pour accéder au 1er contenu ou clique sur le <i>raccourci</i></p>
                    </div>
                  `;

                  document.querySelector(
                    ".find-stars__container"
                  ).style.display = "flex";

                  document.querySelector(".find-stars__mine").style.display =
                    "none";
                },
              });
            });
        },
      });
    }
  }

  resize() {
    this.camera.resize();
    this.renderer.resize();
  }

  update() {
    this.camera.update();
    this.world.update();
    this.renderer.update();

    if (this.myStar) {
      this.myStar.update();
    }
  }

  destroy() {
    this.sizes.off("resize");
    this.time.off("tick");

    // Traverse the whole scene
    this.scene.traverse((child) => {
      // Test if it's a mesh
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();

        // Loop through the material properties
        for (const key in child.material) {
          const value = child.material[key];

          // Test if there is a dispose function
          if (value && typeof value.dispose === "function") {
            value.dispose();
          }
        }
      }
    });

    this.camera.controls.dispose();
    this.renderer.instance.dispose();

    if (this.debug.active) this.debug.ui.destroy();
  }

  initPrismic() {
    const init = async () => {
      const routes = [
        // Update to match your website's URL structure
        { type: "recap-dotas", path: "/:uid" },
        { type: "dota", path: "/:uid" },
      ];

      this.client = prismic.createClient(repositoryName, { routes });

      const prismicDoc = await this.client.getByUID(
        "recap-dotas",
        "recap-dota"
      );
      this.ui.dotas = prismicDoc.data.dota;

      if (this.world.stars?.countNumber) {
        this.ui.initDotaBar();
      }
    };

    init();
  }
}
