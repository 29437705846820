import Experience from "../Experience";

class StarGrid {
  constructor(cellSize) {
    this.experience = new Experience();
    this.cellSize = cellSize;
    this.grid = new Map(); // Utilisation d'une Map pour stocker les étoiles par cellule
  }

  _getCellKey(x, y, z) {
    const cx = Math.floor(x / this.cellSize);
    const cy = Math.floor(y / this.cellSize);
    const cz = Math.floor(z / this.cellSize);
    return `${cx},${cy},${cz}`;
  }

  addStar(star, index) {
    const key = this._getCellKey(star.x, star.y, star.z);
    if (!this.grid.has(key)) {
      this.grid.set(key, []);
    }
    this.grid.get(key).push({ star, index });
  }

  getStarsNear(position, radius) {
    const nearbyStars = [];
    const minX = Math.floor((position.x - radius) / this.cellSize);
    const maxX = Math.floor((position.x + radius) / this.cellSize);
    const minY = Math.floor((position.y - radius) / this.cellSize);
    const maxY = Math.floor((position.y + radius) / this.cellSize);
    const minZ = Math.floor((position.z - radius) / this.cellSize);
    const maxZ = Math.floor((position.z + radius) / this.cellSize);

    for (let x = minX; x <= maxX; x++) {
      for (let y = minY; y <= maxY; y++) {
        for (let z = minZ; z <= maxZ; z++) {
          const key = `${x},${y},${z}`;
          if (this.grid.has(key)) {
            nearbyStars.push(...this.grid.get(key));
          }
        }
      }
    }
    return nearbyStars;
  }

  getStarData(index) {
    for (const stars of this.grid.values()) {
      for (const { star, index: starIndex } of stars) {
        if (starIndex === index) {
          return star;
        }
      }
    }
    return null; // Return null if no star is found with the given index
  }
}

export default StarGrid;
