import gsap from "gsap";
import { EventDispatcher, Quaternion, Vector3 } from "three";
import Experience from "./Experience";

const _changeEvent = { type: "change" };

const interpolationLineairePosition = (min, max, value) => {
  return ((value - min) / (max - min) - 0.5) * 2;
};

const diffVecteurs = (vecA, vecB) => {
  return [vecB.left - vecA.left, vecB.top - vecA.top];
};

class FlyControls extends EventDispatcher {
  constructor(object, domElement) {
    super();

    this.experience = new Experience();

    this.object = object;
    this.domElement = domElement;

    this.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    this.startPosition = {
      left: null,
      top: null,
    };

    this.content = document.querySelector(".content");
    // API

    // Set to false to disable this control
    this.enabled = true;

    this.movementSpeed = 1.0;
    this.rollSpeed = 0.005;

    this.dragToLook = true;
    this.autoForward = false;

    this.hasMoved = false;
    this.wasHasMovedFalseLastFrame = true;

    // disable default target object behavior

    // internals

    const scope = this;

    const EPS = 0.000001;

    const lastQuaternion = new Quaternion();
    const lastPosition = new Vector3();

    this.tmpQuaternion = new Quaternion();

    this.status = 0;

    this.moveState = {
      up: 0,
      down: 0,
      left: 0,
      right: 0,
      forward: 0,
      back: 0,
      pitchUp: 0,
      pitchDown: 0,
      yawLeft: 0,
      yawRight: 0,
      rollLeft: 0,
      rollRight: 0,
    };
    this.moveVector = new Vector3(0, 0, 0);
    this.audioInit = false;
    this.rotationVector = new Vector3(0, 0, 0);

    this.keydown = function (event) {
      this.hasMoved = true;
      if (event.altKey || this.enabled === false) {
        return;
      }
      switch (event.code) {
        case "ShiftLeft":
        case "ShiftRight":
          this.movementSpeedMultiplier = 100;
          break;

        case "KeyW":
          this.moveState.forward = 1;
          break;
        case "KeyS":
          this.moveState.back = 1;
          break;

        case "KeyA":
          this.moveState.left = 1;
          break;
        case "KeyD":
          this.moveState.right = 1;
          break;

        case "KeyR":
          this.moveState.up = 1;
          break;
        case "KeyF":
          this.moveState.down = 1;
          break;

        case "ArrowUp":
          this.moveState.forward = 1;
          break;
        case "ArrowDown":
          this.moveState.back = 1;
          break;

        case "ArrowLeft":
          this.moveState.left = 1;
          break;
        case "ArrowRight":
          this.moveState.right = 1;
          break;

        case "KeyQ":
          this.moveState.rollLeft = 1;
          break;
        case "KeyE":
          this.moveState.rollRight = 1;
          break;
      }

      this.updateMovementVector();
      this.updateRotationVector();
    };

    this.keyup = function (event) {
      if (this.enabled === false) return;

      switch (event.code) {
        case "ShiftLeft":
        case "ShiftRight":
          this.movementSpeedMultiplier = 1;
          break;

        case "KeyW":
          this.moveState.forward = 0;
          break;
        case "KeyS":
          this.moveState.back = 0;
          break;

        case "KeyA":
          this.moveState.left = 0;
          break;
        case "KeyD":
          this.moveState.right = 0;
          break;

        case "KeyR":
          this.moveState.up = 0;
          break;
        case "KeyF":
          this.moveState.down = 0;
          break;

        case "ArrowUp":
          this.moveState.forward = 0;
          break;
        case "ArrowDown":
          this.moveState.back = 0;
          break;

        case "ArrowLeft":
          this.moveState.left = 0;
          break;
        case "ArrowRight":
          this.moveState.right = 0;
          break;

        case "KeyQ":
          this.moveState.rollLeft = 0;
          break;
        case "KeyE":
          this.moveState.rollRight = 0;
          break;
      }

      this.updateMovementVector();
      this.updateRotationVector();
    };

    this.touchstart = function (event) {
      this.hasMoved = true;
      if (this.enabled === false) return;

      if (this.isMobile === false) {
        if (this.dragToLook) {
          this.status++;
        } else {
          switch (event.button) {
            case 0:
              this.moveState.forward = 1;
              break;
            case 2:
              this.moveState.back = 1;
              break;
          }

          this.updateMovementVector();
        }
      } else {
        this.startPosition = {
          left: interpolationLineairePosition(
            0,
            window.innerWidth,
            event.touches[0].pageX
          ),
          top: -interpolationLineairePosition(
            0,
            window.innerHeight,
            event.touches[0].pageY
          ),
        };

        this.moveState.forward = 1;
      }
    };

    this.pointerdown = function (event) {
      this.hasMoved = true;
      if (this.enabled === false) return;

      if (this.isMobile === false) {
        if (this.dragToLook) {
          this.status++;
          switch (event.button) {
            case 0:
              this.moveState.forward = 1;
              break;
            case 2:
              this.moveState.back = 1;
              break;
          }

          this.updateMovementVector();
          this.updateRotationVector();
        }
      }
    };

    this.touchmove = function (event) {
      this.hasMoved = true;
      if (this.enabled === false) return;

      if (!this.isMobile) {
        if (!this.dragToLook || this.status > 0) {
          const container = this.getContainerDimensions();
          const halfWidth = container.size[0] / 2;
          const halfHeight = container.size[1] / 2;

          this.moveState.yawLeft =
            -(event.pageX - container.offset[0] - halfWidth) / halfWidth;
          this.moveState.pitchDown =
            (event.pageY - container.offset[1] - halfHeight) / halfHeight;

          this.updateRotationVector();
        }
      } else {
        const width = window.innerWidth;
        const height = window.innerHeight;

        this.currentPoint = {
          left: interpolationLineairePosition(
            0,
            window.innerWidth,
            event.touches[0].pageX
          ),
          top: -interpolationLineairePosition(
            0,
            window.innerHeight,
            event.touches[0].pageY
          ),
        };

        const diffStartAndPos = diffVecteurs(
          this.currentPoint,
          this.startPosition
        );

        this.moveState.yawRight = -diffStartAndPos[0] * 1.4;
        this.moveState.yawUp = diffStartAndPos[1] * 1.4;

        // Calcul de `pitchDown` comme une combinaison des deux mouvements
        this.moveState.pitchDown = this.moveState.yawUp;

        // Mise à jour des vecteurs
        this.updateMovementVector();
        this.updateRotationVector();
      }
    };

    this.pointermove = function (event) {
      this.hasMoved = true;
      if (this.enabled === false) return;

      if (!this.isMobile) {
        if (!this.dragToLook || this.status > 0) {
          const container = this.getContainerDimensions();
          const halfWidth = container.size[0] / 2;
          const halfHeight = container.size[1] / 2;

          this.moveState.yawLeft =
            -(event.pageX - container.offset[0] - halfWidth) / halfWidth;
          this.moveState.pitchDown =
            (event.pageY - container.offset[1] - halfHeight) / halfHeight;

          this.updateRotationVector();
        }
      }
    };

    this.pointerup = function (event) {
      if (this.enabled === false) return;

      if (this.isMobile === false) {
        this.status = 0;
        if (this.dragToLook) {
          switch (event.button) {
            case 0:
              this.moveState.forward = 0;
              break;
            case 2:
              this.moveState.back = 0;
              break;
          }

          this.moveState.forward = false;
          this.moveState.right = 0;
          this.moveState.left = 0;
          this.moveState.up = 0;
          this.moveState.down = 0;
          this.moveState.yawRight = 0;
          this.moveState.yawUp = 0;
          this.moveState.pitchDown = 0;
          this.moveState.rollSpeed = 0;
          this.moveState.pitchUp = 0;
          this.moveState.yawLeft = 0;
          this.moveState.rollRight = 0;
          this.moveState.rollLeft = 0;

          this.updateMovementVector();
          this.updateRotationVector();
        } else {
          switch (event.button) {
            case 0:
              this.moveState.forward = 0;
              break;
            case 2:
              this.moveState.back = 0;
              break;
          }

          this.updateMovementVector();
        }
      }
    };

    this.pointerleave = function (event) {
      if (this.enabled === false) return;

      if (this.isMobile === false) {
        this.status = 0;
        if (this.dragToLook) {
          switch (event.button) {
            case 0:
              this.moveState.forward = 0;
              break;
            case 2:
              this.moveState.back = 0;
              break;
          }

          this.moveState.forward = false;
          this.moveState.right = 0;
          this.moveState.left = 0;
          this.moveState.up = 0;
          this.moveState.down = 0;
          this.moveState.yawRight = 0;
          this.moveState.yawUp = 0;
          this.moveState.pitchDown = 0;
          this.moveState.rollSpeed = 0;
          this.moveState.pitchUp = 0;
          this.moveState.yawLeft = 0;
          this.moveState.rollRight = 0;
          this.moveState.rollLeft = 0;

          this.updateMovementVector();
          this.updateRotationVector();
        } else {
          switch (event.button) {
            case 0:
              this.moveState.forward = 0;
              break;
            case 2:
              this.moveState.back = 0;
              break;
          }

          this.updateMovementVector();
        }
      }
    };

    this.touchleave = function (event) {
      if (this.enabled === false) return;

      if (this.isMobile === false) {
        if (this.dragToLook) {
          this.status--;

          this.moveState.yawLeft = this.moveState.pitchDown = 0;
        } else {
          switch (event.button) {
            case 0:
              this.moveState.forward = 0;
              break;
            case 2:
              this.moveState.back = 0;
              break;
          }

          this.updateMovementVector();
        }

        this.updateRotationVector();
      } else {
        this.startPosition = {
          left: null,
          top: null,
        };

        this.moveState.forward = false;
        this.moveState.right = 0;
        this.moveState.left = 0;
        this.moveState.up = 0;
        this.moveState.down = 0;
        this.moveState.yawRight = 0;
        this.moveState.yawUp = 0;
        this.moveState.pitchDown = 0;
        this.moveState.rollSpeed = 0;
        this.rollSpeed = 0;
      }
    };

    this.touchcancel = function () {
      if (this.enabled === false) return;

      if (this.isMobile === false) {
        if (this.dragToLook) {
          this.status = 0;

          this.moveState.yawLeft = this.moveState.pitchDown = 0;
        } else {
          this.moveState.forward = 0;
          this.moveState.back = 0;
        }
      } else {
        this.moveState.forward = false;
        this.moveState.right = 0;
        this.moveState.left = 0;
        this.moveState.up = 0;
        this.moveState.down = 0;
        this.moveState.yawRight = 0;
        this.moveState.yawUp = 0;
        this.moveState.pitchDown = 0;
      }
      this.updateMovementVector();
      this.updateRotationVector();
    };

    this.contextMenu = function (event) {
      if (this.enabled === false) return;

      event.preventDefault();
    };

    this.touchend = function (event) {
      if (this.isMobile === true) {
        this.moveState.forward = 0;
        this.moveState.right = 0;
        this.moveState.left = 0;
        this.moveState.up = 0;
        this.moveState.down = 0;
        this.moveState.yawRight = 0;
        this.moveState.yawUp = 0;
        this.moveState.pitchDown = 0;
        this.updateMovementVector();
        this.updateRotationVector();
      } else {
      }
    };

    this.update = function (delta) {
      if (
        document &&
        this.experience.world.mainStar &&
        this.experience.world.mainStar.audioLaunched === false
      ) {
        const audioContext = this.experience.world.mainStar.sound;
        if (audioContext?.context) {
          if (audioContext.state === "running") {
            this.experience.world.mainStar.audioLaunched = true;
          }
        } else {
          if (this.audioInit === false) {
            this.experience.world.mainStar.createSpatialAudio();
            this.audioInit = true;
          }
        }
      }

      if (this.enabled === false) return;

      const moveMult = delta * scope.movementSpeed;
      const rotMult = delta * scope.rollSpeed;

      scope.object.translateX(scope.moveVector.x * moveMult);
      scope.object.translateY(scope.moveVector.y * moveMult);
      scope.object.translateZ(scope.moveVector.z * moveMult);

      scope.tmpQuaternion
        .set(
          scope.rotationVector.x * rotMult,
          scope.rotationVector.y * rotMult,
          scope.rotationVector.z * rotMult,
          1
        )
        .normalize();
      scope.object.quaternion.multiply(scope.tmpQuaternion);

      if (
        lastPosition.distanceToSquared(scope.object.position) > EPS ||
        8 * (1 - lastQuaternion.dot(scope.object.quaternion)) > EPS
      ) {
        scope.dispatchEvent(_changeEvent);
        lastQuaternion.copy(scope.object.quaternion);
        lastPosition.copy(scope.object.position);
      }
    };

    this.updateMovementVector = function () {
      const forward =
        this.moveState.forward || (this.autoForward && !this.moveState.back)
          ? 0.8
          : 0;

      this.moveVector.x = -this.moveState.left + this.moveState.right;
      this.moveVector.y = -this.moveState.down + this.moveState.up;
      this.moveVector.z = -forward + this.moveState.back;

      if (forward > 0) {
        this.content.classList.add("hidden");
      } else {
        this.content.classList.remove("hidden");
      }
    };

    this.updateRotationVector = function () {
      this.rotationVector.x =
        -this.moveState.pitchDown + this.moveState.pitchUp;
      this.rotationVector.y = -this.moveState.yawRight + this.moveState.yawLeft;
      this.rotationVector.z =
        -this.moveState.rollRight + this.moveState.rollLeft;
    };

    this.getContainerDimensions = function () {
      if (this.domElement != document) {
        return {
          size: [this.domElement.offsetWidth, this.domElement.offsetHeight],
          offset: [this.domElement.offsetLeft, this.domElement.offsetTop],
        };
      } else {
        return {
          size: [window.innerWidth, window.innerHeight],
          offset: [0, 0],
        };
      }
    };

    this.dispose = function () {
      this.domElement.removeEventListener("contextmenu", _contextmenu);
      this.domElement.removeEventListener("touchstart", _touchstart);
      this.domElement.removeEventListener("touchmove", _touchmove);
      this.domElement.removeEventListener("touchleave", _touchleave);
      this.domElement.removeEventListener("touchcancel", _touchcancel);
      this.domElement.removeEventListener("touchend", _touchend);
      this.domElement.removeEventListener("pointerdown", _pointerdown);
      this.domElement.removeEventListener("pointermove", _pointermove);
      this.domElement.removeEventListener("pointerup", _pointerup);
      this.domElement.removeEventListener("pointerleave", _pointerleave);

      // window.removeEventListener("keydown", _keydown);
      // window.removeEventListener("keyup", _keyup);
    };

    const _contextmenu = this.contextMenu.bind(this);
    const _touchmove = this.touchmove.bind(this);
    const _touchstart = this.touchstart.bind(this);
    const _touchleave = this.touchleave.bind(this);
    const _touchend = this.touchend.bind(this);
    const _touchcancel = this.touchcancel.bind(this);
    const _keydown = this.keydown.bind(this);
    const _keyup = this.keyup.bind(this);
    const _pointerdown = this.pointerdown.bind(this);
    const _pointermove = this.pointermove.bind(this);
    const _pointerup = this.pointerup.bind(this);
    const _pointerleave = this.pointerleave.bind(this);

    this.domElement.addEventListener("contextmenu", _contextmenu);
    this.domElement.addEventListener("touchstart", _touchstart);
    this.domElement.addEventListener("pointerdown", _pointerdown);
    this.domElement.addEventListener("pointermove", _pointermove);
    this.domElement.addEventListener("pointerup", _pointerup);
    this.domElement.addEventListener("pointerleave", _pointerleave);
    this.domElement.addEventListener("touchmove", _touchmove);
    this.domElement.addEventListener("touchleave", _touchleave);
    this.domElement.addEventListener("touchcancel", _touchcancel);
    this.domElement.addEventListener("touchend", _touchend);

    // window.addEventListener("keydown", _keydown);
    // window.addEventListener("keyup", _keyup);

    this.updateMovementVector();
    this.updateRotationVector();
  }
}

export { FlyControls };
