import gsap from "gsap";
import Experience from "../Experience.js";
import MovementParticles from "../MovementParticles.js";
import Environment from "./Environment.js";
import MainStar from "./MainStar.js";
import Stars from "./Stars.js";
// import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import ExStars from "./ExStars.js";
import StarGrid from "./GridStar.js";
import * as THREE from "three";
import { color } from "three/tsl";
import { FontLoader } from "three/examples/jsm/Addons.js";

import HelveticaFont from "three/examples/fonts/helvetiker_regular.typeface.json" assert { type: "json" };

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // Wait for resources
    this.resources.on("ready", () => {
      // Setup
      this.environment = new Environment();
      this.starGrid = new StarGrid(5); // Cellules de 50 unités

      this.stars = new Stars();
      this.mainStar = new MainStar();
      this.movementsParticles = new MovementParticles();
      this.experience.init();
      // this.exstars = new ExStars();
    });
  }

  update() {
    this.stars?.update();
    this.mainStar?.update();
    this.movementsParticles?.update();
    // this.exstars?.update();

    if (
      this.movementsParticles &&
      this.experience.camera.controls.moveState.forward
    ) {
      if (this.experience.world.particleGroup) {
        gsap.to(this.experience.camera.instance, {
          fov: 65,
          duration: 0.3,
          onUpdate: () => {
            this.experience.camera.instance.updateProjectionMatrix();
          },
          onComplete: () => {
            this.experience.camera.instance.fov = 65;
            this.experience.camera.instance.updateProjectionMatrix();
          },
        });
        this.experience.world.particleGroup.visible = true;
      }
    } else {
      gsap.to(this.experience.camera.instance, {
        fov: 55,
        duration: 0.3,
        onUpdate: () => {
          this.experience.camera.instance.updateProjectionMatrix();
        },
        onComplete: () => {
          this.experience.camera.instance.fov = 65;
          this.experience.camera.instance.updateProjectionMatrix();
        },
      });

      if (this.experience.world.particleGroup) {
        this.experience.world.particleGroup.visible = false;
      }
      // this.experience.movementsParticles.texture.opacity = 1;
    }
  }
}
