import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import EventEmitter from "./EventEmitter.js";
import gsap from "gsap";
import Experience from "../Experience.js";

export default class Resources extends EventEmitter {
  constructor(sources) {
    super();

    this.experience = new Experience();

    this.sources = sources;

    this.items = {};
    this.toLoad = this.sources.length;
    this.loaded = 0;

    this.setLoaders();
    this.startLoading();
  }

  setLoaders() {
    this.loaders = {};
    this.loaders.gltfLoader = new GLTFLoader();
    this.loaders.textureLoader = new THREE.TextureLoader();
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader();
    this.loaders.soundLoader = new THREE.AudioLoader();
  }

  startLoading() {
    // Load each source
    for (const source of this.sources) {
      if (source.type === "gltfModel") {
        this.loaders.gltfLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      } else if (source.type === "texture") {
        this.loaders.textureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      } else if (source.type === "cubeTexture") {
        this.loaders.cubeTextureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      }
      //  else if (source.type === "audioSound") {
      //   this.loaders.soundLoader.load(source.path, (file) => {
      //     this.sourceLoaded(source, file);
      //   });
      // }
    }
  }

  sourceLoaded(source, file) {
    this.items[source.name] = file;

    this.loaded++;

    document.querySelector(
      ".visual-loader .inner-loader"
    ).style.width = `calc(${(this.loaded / this.toLoad) * 100}% - 30px)`;

    if (this.loaded === this.toLoad) {
      this.trigger("ready");

      setTimeout(() => {
        document.querySelector(
          ".visual-loader .inner-loader"
        ).style.width = `100%`;

        gsap.to(".visual-loader", {
          opacity: 0,
          display: "none",
          // y: "100%",
          delay: 1,
          onComplete: () => {
            gsap.fromTo(
              ".header .main_logo",
              { opacity: 0, y: 30 },
              { opacity: 1, y: 0, duration: 0.3 }
            );
            gsap.fromTo(
              ".header .objectif_barre--content",
              { opacity: 0, y: 30 },
              { opacity: 1, y: 0, duration: 0.3, delay: 0.3 }
            );
            gsap.fromTo(
              ".header",
              { opacity: 0 },
              { opacity: 1, duration: 0.3 }
            );
          },
        });

        setTimeout(() => {
          this.experience.isFinishedLoader = true;
          this.experience.world.stars.setMesh();
          this.experience.world.stars.setupEvents(); // Gérer les clics
        }, 800);
      }, 1000);
    }
  }
}
