export default [
  {
    name: "environmentMapTexture",
    type: "cubeTexture",
    path: [
      "textures/environmentMap/space/px.jpg",
      "textures/environmentMap/space/nx.jpg",
      "textures/environmentMap/space/py.jpg",
      "textures/environmentMap/space/ny.jpg",
      "textures/environmentMap/space/pz.jpg",
      "textures/environmentMap/space/nz.jpg",
    ],
  },
  {
    name: "starLens1",
    type: "texture",
    path: "textures/stars/lensflare0.png",
  },
  // {
  //   name: "prodMain",
  //   type: "audioSound",
  //   path: "sounds/output.mp3",
  // },
];
