import gsap from "gsap";
import BlueRightArrow from "../assets/img/blue_right-arrow.svg";
import Experience from "./Experience";
import texts from "./texts";

export default class DotasPanel {
  constructor() {
    this.experience = new Experience();
    this.ui = this.experience.ui;
    this.isConnectedUser = false;

    if (localStorage.getItem("user")) {
      this.isConnectedUser = true;
    }

    this.init();
  }

  init() {
    this.createJauge();
    // this.initDotaPresentationContainer();
  }

  resetDotaPanel() {
    document.querySelector(".dotas_panel").innerHTML = "";
    this.initDotaPresentationContainer();
    this.initUnlockedEventListeners();
  }

  openDotaPanel() {
    gsap.to(".dota__presentation-panel_container", {
      duration: 0.3,
      x: 0,
      opacity: 1,
      display: "flex",
      ease: "power2.inOut",
      onComplete: () => {
        this.experience.world.mainStar.sound.pause();
      },
    });
  }

  closeDotaPanel() {
    gsap.to(".dota__presentation-panel_container", {
      duration: 0.3,
      x: "100%",
      opacity: 0,
      display: "none",
      ease: "power2.inOut",
      onComplete: () => {
        this.experience.world.mainStar.sound.play();
      },
    });
  }

  initDotaPanel() {
    document.querySelector(".dotas_panel").innerHTML += `
      <div class="dota__presentation-current_dota section_dota"></div>
    `;

    document.querySelector(".dotas_panel").innerHTML += `
      <div class="dota__presentation-unlocked_dota section_dota ${
        this.isConnectedUser ? "connected" : "disconnected"
      }">
        <div class="title_dota__presentation">
          <h2>${
            this.isConnectedUser
              ? texts.dotas.section.title.connected
              : texts.dotas.section.title.disconnected
          }</h2>
        </div>
      </div>
    `;

    // document.querySelector(".dotas_panel").innerHTML += `
    //   <div class="dota__presentation-locked_dota section_dota">
    //     <div class="title_dota__presentation">
    //       <h2>Contenus à venir</h2>
    //     </div>
    //   </div>
    // `

    document.querySelector(".dotas_panel").innerHTML += `
      <div class="dota__presentation-locked_dota section_dota">
      </div>
    `;
  }

  async initDotaPresentationContainer() {
    // On créé le panel
    this.initDotaPanel();

    this.dota_association = this.ui.getElementByNbInscrits(
      this.ui.dotas,
      this.experience.world.stars.countNumber
    );

    // On initialise les dotas unlocked
    this.ui.dotas.forEach((dota) => {
      if (dota.nb_inscrits <= this.experience.world.stars.countNumber) {
        this.addAnUnlockedDota(
          dota.nb_inscrits,
          dota.nom[0].text,
          dota.linked_ui
        );
      }
    });

    if (this?.dota_association?.linked_ui) {
      this.currentDota = await this.experience.client.getByUID(
        "dota",
        this.dota_association.linked_ui
      );

      const idNextDota =
        this.ui.dotas.findIndex(
          (el) => el.linked_ui === this.dota_association.linked_ui
        ) + 1;

      // On ajoute la dota actuelle
      this.addCurrentDota(
        this.dota_association.nb_inscrits,
        this.experience.world.stars.countNumber,
        this.dota_association.nom[0].text
      );

      for (let index = 0; index < this.ui.dotas.length; index++) {
        if (index >= idNextDota + 1) {
          this.addFutureDota(this.ui.dotas[index].nom[0].text);
        }
      }
    }

    if (this.experience.myStar) {
      this.initUnlockedEventListeners();
    }
  }

  async createJauge() {
    this.dota_association = this.ui.getElementByNbInscrits(
      this.ui.dotas,
      this.experience.world.stars.countNumber
    );

    if (this?.dota_association?.linked_ui) {
      this.currentDota = await this.experience.client.getByUID(
        "dota",
        this.dota_association.linked_ui
      );

      const idNextDota =
        this.ui.dotas.findIndex(
          (el) => el.linked_ui === this.dota_association.linked_ui
        ) + 1;

      const jauge = document.querySelector(".objectif_barre--content");

      jauge.innerHTML += `<div class="objectif_barre--inner" style="width: calc(${
        (this.experience.world.stars.countNumber /
          this.dota_association.nb_inscrits) *
        100
      }% - 4px)"><p>${this.experience.world.stars.countNumber}</p></div>`;

      jauge.innerHTML += `<p class="under_bar">/${this.dota_association.nb_inscrits}</p>`;

      this.resetDotaPanel();
    } else {
      const jauge = document.querySelector(".objectif_barre--content");

      jauge.innerHTML += `<div class="objectif_barre--inner" style="width: calc(100% - 4px); align-items: center;"><p>${this.experience.world.stars.countNumber} étoiles</p></div>`;
      this.resetDotaPanel();
    }
  }

  initUnlockedEventListeners() {
    document
      .querySelectorAll(".dota__presentation-unlocked_dota .dota_container")
      .forEach((el) => {
        el.addEventListener("click", (e) => {
          window.digitalData.page.pageInfo.pageName =
            "Leman:Toutes les etoiles:Open Dota " + el.dataset.id;
          document.dispatchEvent(new CustomEvent("SPAPageTriggered"));
          this.ui.handleDotaGeter(el.dataset.id);
        });
      });
  }

  addAnUnlockedDota(value, dota_name, id) {
    document.querySelector(".dota__presentation-unlocked_dota").innerHTML += `
      <div data-id="${id}" class="dota_container">
        <div class="blue_right-arrow-container">
          <img
            src="${BlueRightArrow}"
            class="blue_right-arrow"
          />
        </div>
        <div class="bar_container">
          <div class="bar_outer">
            <div class="bar_inner"><p>${value}</p></div>
          </div>
        </div>
        <p class="dota_name">${dota_name}</p>
      </div>
    `;
  }

  addFutureDota(dota_name) {
    document.querySelector(".dota__presentation-locked_dota").innerHTML += `
      <div class="dota_container">
        <div class="bar_container">
          <div class="bar_outer">
            <p class="avenir">À VENIR</p>
              <div class="bar_inner">
            </div>
          </div>
        </div>
        <p class="dota_name">${dota_name}</p>
      </div>
    `;
  }

  addCurrentDota(value, currentNumber, dota_name) {
    document.querySelector(".dota__presentation-current_dota").innerHTML = `
      <div class="dota_container">
        <div class="bar_container">
          <p>/${value}</p>
          <div class="bar_outer">
            <div class="bar_inner" style='width: ${
              (currentNumber / value) * 100 + "%"
            };'><p>${currentNumber}</p></div>
          </div>
        </div>
        <p class="dota_name">${dota_name}</p>
      </div>
    `;
  }
}
